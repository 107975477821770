<template>
    <div>
        <HeadContent>
            <img
                    slot="bgImg"
                    src="../../assets/common/topTitleBg.png"
                    style="margin-top: 70px"
            />
            <div slot="title" style="width: 100%">企业概况</div>
        </HeadContent>
        <!--    企业概况 s-->
        <div class="qygk">
            <span class="mtitle">企业概况</span>
            <div class="qygk-t">
                <div class="qygk-t-l">
                    <div class="btitle" style="font-size: 40px">
                        重庆国科食品检测<br>
                        有限公司
                    </div>
                    <div class="qygk-t-l-c">
                        是一家专业的第三方检验认证机构，集科研、培训、服务于一体
                        的高科技服务型企业。
                    </div>
                    <div class="qygk-t-l-b">
                        <div class="qygk-t-l-b-col">
                            <div>
                                <span>200</span>
                                <span>服务企业</span>
                            </div>
                            <div>+</div>
                        </div>

                        <div class="qygk-t-l-b-col">
                            <div>
                                <span>270</span>
                                <span>先进分析仪器</span>
                            </div>
                            <div>+</div>
                        </div>

                        <div class="qygk-t-l-b-col">
                            <div>
                                <span>950</span>
                                <span>检测项目</span>
                            </div>
                            <div>+</div>
                        </div>

                        <div class="qygk-t-l-b-col">
                            <div>
                                <span>3000</span>
                                <span>实验室面积</span>
                            </div>
                            <div>+</div>
                        </div>
                    </div>
                </div>
                <div class="qygk-t-r">
                    <img src="./images/Gsituation/蒙版组 148.png" alt="">
                </div>
            </div>
        </div>
        <!--    企业概况 e-->


        <!--    专家团队 s-->
        <div class="gkzjtd">
            <span class="mtitle">专家团队</span>
            <div class="gkzjtd-c">
                <div class="gkzjtd-c-col" @click="goRouter('specialist')">
                    <div class="gkzjtd-c-col-c">
                        <img src="./images/Gsituation/蒙版组 37.png" alt="">
                        <div>
                            <span>汪学荣</span>
                            <span>
                西南大学副教授/硕士生导师<br>
                西部食品产业技术研究院<br>
                肉类研究中心 负责人
              </span>
                        </div>
                    </div>
                </div>

                <div class="gkzjtd-c-col" @click="goRouter('specialist')">
                    <div class="gkzjtd-c-col-c">
                        <img src="./images/Gsituation/蒙版组 38.png" alt="">
                        <div>
                            <span>王群</span>
                            <span>
                重庆国科<br>
                总经理/技术负责人
              </span>
                        </div>
                    </div>
                </div>

                <div class="gkzjtd-c-col" @click="goRouter('specialist')">
                    <div class="gkzjtd-c-col-c">
                        <img src="./images/Gsituation/蒙版组 228.png" alt="">
                        <div>
                            <span>刘雄</span>
                            <span>
                西南大学教授/博士生导师<br>
                西部食品产业技术研究院
院长
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--    专家团队 e-->

        <!--    理化实验室 s-->
        <div class="lhsys">
            <div class="lhsys-c">
                <div class="lhsys-c-l">
                    <img src="./images/Gsituation/蒙版组 48.png" alt="">
                </div>
                <div class="lhsys-c-r">
                    <div>理化实验室</div>
                    <div>
                        配备全制动电位滴定仪，脂肪蛋白质测定
                        仪，自动旋光仪，比较测色仪、谷物电子
                        容重器、双光束紫外可见分光光度计等。
                    </div>
                    <div>
                        <i class="el-icon-back"></i>
                        <i class="el-icon-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--    理化实验室 e-->
        <!--    检测范围 s-->
        <div class="jcfw">
            <span class="mtitle">检测范围</span>
            <div class="jcfw-c">
                <div v-for="(item,index) in jcfwList" :key="index">
                    <img :src="item.img" alt="">
                    <span>{{ item.tit }}</span>
                    <span v-if="item.tit2">{{ item.tit2 }}</span>
                </div>
            </div>
        </div>
        <!--    检测范围 e-->

        <!--    检测仪器 s-->
        <div class="jcyq">
            <span class="mtitle">检测仪器</span>
            <div class="jcyq-c">
                <div class="jcyq-c-l">
                    <img src="./images/Gsituation/安捷伦 agilent  气相色谱质谱联用仪.png" alt="">
                    <span>
           安捷伦 agilent  气相色谱质谱联用仪
          </span>
                </div>
                <div class="jcyq-c-r">
                    <div>以设备服务大众</div>
                    <div>以高性能产品满足最严苛的分析需求</div>
                    <div>
                        <i class="el-icon-back"></i>
                        <i class="el-icon-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--    检测仪器 e-->

        <!--&lt;!&ndash;    发展历程 s&ndash;&gt;-->
        <!--    <div class="fzlc">-->
        <!--      <span class="mtitle">发展历程</span>-->
        <!--      <div class="fzlc-c">-->
        <!--        <el-collapse v-model="activeNames">-->
        <!--          <el-collapse-item title="2021" name="1">-->
        <!--&lt;!&ndash;            <div class="blubar"></div>&ndash;&gt;-->
        <!--            <div class="licheng">-->
        <!--              <div class="">-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span>公司认证扩项至950项</span>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </el-collapse-item>-->
        <!--          <el-collapse-item title="2020" name="2">-->
        <!--            <div class="licheng">-->
        <!--              <div class="">-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span>公司认证扩项至950项</span>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </el-collapse-item>-->
        <!--          <el-collapse-item title="2019" name="3">-->
        <!--            <div class="licheng">-->
        <!--              <div class="">-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span>公司认证扩项至950项</span>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </el-collapse-item>-->
        <!--          <el-collapse-item title="2015" name="4">-->
        <!--            <div class="licheng">-->
        <!--              <div class="">-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span>公司认证扩项至950项</span>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <span>2021.4</span>-->
        <!--                <span-->
        <!--                >与西南大学、重庆文理学院签订战略合作协议，成立实践教育基地，全面开展产学研工作</span-->
        <!--                >-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </el-collapse-item>-->
        <!--        </el-collapse>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--&lt;!&ndash;    发展历程 e&ndash;&gt;-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                jcfwList: [
                    {
                        img: require("./images/Gsituation/jcfw (4).png"),
                        tit: "水质检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (3).png"),
                        tit: "营养成分",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (7).png"),
                        tit: "兽药渔药",
                        tit2: "残留检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (6).png"),
                        tit: "有毒有害",
                        tit2: "物质检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (2).png"),
                        tit: "微生物检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (9).png"),
                        tit: "农药检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (8).png"),
                        tit: "元素检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (10).png"),
                        tit: "非法食品",
                        tit2: "添加剂检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (5).png"),
                        tit: "添加剂检测",
                    },
                    {
                        img: require("./images/Gsituation/jcfw (1).png"),
                        tit: "污染物检测",
                    },
                ],
                activeNames: ["1"],
            };
        },
    };
</script>

<style lang="scss" scoped>
    //企业概况 s
    .qygk {
        @include page1200;
        padding: 88px 0 140px;

        .qygk-t {
            margin-top: 40px;
            display: flex;

            .qygk-t-l {
                .qygk-t-l-c {
                    width: 334px;
                    padding-top: 10px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 16px;
                    color: rgba(#606060, 0.76);
                    margin-bottom: 45px;
                }

                .qygk-t-l-b {
                    display: grid;
                    grid-template-rows: repeat(2, 1fr);
                    grid-template-columns: repeat(2, 1fr);

                    .qygk-t-l-b-col {
                        display: flex;

                        div:nth-child(1) {
                            display: flex;
                            flex-direction: column;

                            span:nth-child(1) {
                                font-size: 52px;
                                font-family: PingFang SC;
                                font-weight: bold;
                                color: #333333;
                            }

                            span:nth-child(2) {
                                font-size: 15px;
                                font-family: PingFang SC;
                                font-weight: 500;
                                color: #606060;
                            }

                        }

                        div:nth-child(2) {
                            margin-left: 14px;
                            font-size: 50px;
                            color: #666;
                            height: 100%;
                            @include flexCenter;
                        }
                    }
                }
            }

            .qygk-t-r {
                margin-left: 265px;
                width: 565px;
                height: 407px;
                position: relative;

                &:after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    right: 0;
                    width: 52px;
                    height: 309px;
                    transform: translateX(50%);
                    background: #1320E8;
                    z-index: -1;
                }
            }

        }
    }

    //企业概况 e

    //专家团队 s
    .gkzjtd {
        margin-top: 90px;
        padding-bottom: 90px;
        @include page1200;

        .gkzjtd-c {
            padding-top: 40px;
            display: flex;
            flex-direction: row;

            .gkzjtd-c-col {
                width: 314px;
                height: 179px;
                background: rgba(#D1D3D4, .5);
                margin-left: 140px;
                @include flexCenter;

                &:hover {
                    cursor: pointer;
                }

                &:first-child {
                    margin-left: 80px;
                }

                .gkzjtd-c-col-c {
                    display: flex;
                    align-items: center;
                    transform: translateX(-80px);

                    img {
                        background: #1320E8;
                        margin-right: 20px;
                        width: 150px;
                        height: 115px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;

                        span {
                            display: block;
                            white-space: nowrap;
                        }

                        span:nth-child(1) {
                            font-size: 24px;
                            margin-bottom: 6px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        span:nth-child(2) {
                            font-size: 15px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #888888;
                        }
                    }
                }
            }
        }
    }

    //专家团队 e

    //理化实验室 s
    .lhsys {
        width: 100%;
        height: 500px;
        background: #1320E8;

        .lhsys-c {
            @include page1200;
            display: flex;

            img {
                width: 721px;
            }

            .lhsys-c-r {
                padding: 97px 57px;

                div:nth-child(1) {
                    font-size: 50px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    line-height: 60px;
                    color: #FFFFFF;
                }

                div:nth-child(2) {
                    width: 260px;
                    height: 80px;
                    margin-top: 11px;
                    margin-bottom: 80px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 16px;
                    color: rgba(#FFFFFF, 0.76);
                }

                div:nth-child(3) {
                    color: #FFFFFF;

                    i {
                        margin-right: 50px;
                    }
                }
            }
        }
    }

    //理化实验室 e

    //检测范围 s
    .jcfw {
        padding-top: 90px;
        margin-bottom: 20px;
        @include page1200;

        .jcfw-c {
            margin-top: 40px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            div {
                padding-top: 30px;
                margin-right: 74px;
                width: 180px;
                height: 185px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 74px;

                img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 32px;
                }

                span {
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 30px;
                    color: #606060;
                }

                &:nth-child(5n) {
                    margin-right: 0;
                }

                &:hover {
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
                }
            }
        }
    }

    //检测范围 e

    //检测仪器 s
    .jcyq {
        @include page1200;
        padding-bottom: 90px;
        .jcyq-c {
            margin-top: 40px;
            width: 100%;
            height: 452px;
            background: #FBFBFB;
            padding-top: 49px;
            padding-left: 193px;
            display: flex;
            .jcyq-c-l {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .jcyq-c-r {
                padding-top: 44px;
                padding-left: 174px;
                div:nth-child(1) {
                    font-size: 50px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    line-height: 60px;
                    color: #606060;
                }
                div:nth-child(2) {
                    margin: 10px 0 80px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 16px;
                    color: #606060;
                }
                div:nth-child(3) {
                    color: #707070;
                    i {
                        margin-right: 50px;
                    }
                }
            }
        }
    }

    //检测仪器 e

</style>
